<template>
<div class="my-4">
  <b-container>
    <b-row class="mb-4" v-if="!loading">
      <b-col>
        <b-card class="mb-3">
          <b-container>
            <b-row>
              <b-col>
                  <b-dropdown id="tags-dropdown" variant="outline-primary" :text="tag.name" size="lg">
                    <b-dropdown-item  v-for="item in aitags" :key="'tags' + item.id">
                      <router-link :to="{ name: 'NewsAiTag', params: { id: item.id}}" class="dropdown-item">{{item.name}}</router-link>
                    </b-dropdown-item>
                  </b-dropdown>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            v-bind:propItem="item"
            v-bind:key="item.id"
            :tagsProp=tags
            v-bind:username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionReadTagsPanel="permissionReadTagsPanel"
            />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-show="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row v-show="!loadingNews" class="text-center mb-4" align-h="center">
        <b-col>
            <b-button variant="primary" size="sm" v-on:click="load">Load more</b-button>
        </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'
import moment from 'moment'
import Publication from '@/components/PublicationNew'

export default {
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'news AI tag', action: 'open news AI tag', model: 'aitag', model_id: this.$route.params.id })
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted

    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
    }
    this.date = moment()
    this.tags = await this.$Amplify.API.get('cosmos', '/tags')
    this.aitags = await this.$Amplify.API.get('cosmos', '/standard/aitag')
    await this.firstLoad()
  },
  data () {
    return {
      aitags: [],
      loading: true,
      quarter: '',
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      item: {},
      loadingNews: false,
      news: [],
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      tag: {},
      tags: [],
      limit: 50,
      offset: 0,
      page: 0
    }
  },
  methods: {
    firstLoad: async function () {
      this.$logger.debug('load started')
      this.loadingNews = true
      this.year = moment(this.date).year()
      this.quarter = moment(this.date).quarter()
      this.tag = this.aitags.find(tag => parseInt(tag.id) === parseInt(this.$route.params.id))
      try {
        let apiName = 'cosmos'
        let path = `/news/aitag/${this.$route.params.id}/limit/${this.limit}/offset/0`
        this.$logger.debug(path)
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('load response: ', response)
        this.news = response
        this.$logger.debug(this.news)
        this.page = this.page + 1
        this.offset = this.page * this.limit
        this.loading = false
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('load loading error' + e)
      }
    },
    load: async function () {
      this.$logger.debug('load started')
      this.loadingNews = true
      this.year = moment(this.date).year()
      this.quarter = moment(this.date).quarter()
      this.tag = this.aitags.find(tag => parseInt(tag.id) === parseInt(this.$route.params.id))
      try {
        let apiName = 'cosmos'
        let path = `/news/aitag/${this.$route.params.id}/limit/${this.limit}/offset/${this.offset}`
        this.$logger.debug(path)
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('load response: ', response)
        let news = response
        // push into existing array
        for (let i = 0, len = news.length; i < len; i++) {
          this.news.push(news[i])
        }
        this.$logger.debug(this.news)
        this.page = this.page + 1
        this.offset = this.page * this.limit
        this.loading = false

        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('load loading error' + e)
      }
    }
  },
  watch: {
    $route: async function () {
      this.$stat.log({ page: 'news AI tag', action: 'load new AI tag', model: 'aitag', model_id: this.$route.params.id })
      this.loading = true
      await this.firstLoad()
    }
  }
}
</script>

<style>
</style>
